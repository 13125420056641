@charset "UTF-8";

// color
$color-pink: #E87BA7;
$color-yellow: #F6C01D;
$color-blue: #009CD7;
$color-green: #6BB846;
$color-orange: #E66F1C;
$color-red-orange: #e63916;
$color-purple: #7D4294;
$color-grey: #AFAFAF;

// font weight
$weight-light: 200;
$weight-demilight: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 700;
$weight-black: 900;

// icon
$icon-arrow: '\e901';
$icon-cart: '\e900';
$icon-download: '\e903';
$icon-blank: '\e902';

// spacing
$spacing-3: 3px;
$spacing-5: 5px;
$spacing-10:10px;
$spacing-15:15px;
$spacing-20:20px;
$spacing-25:25px;
$spacing-30:30px;
$spacing-35:35px;
$spacing-40:40px;
$spacing-45:45px;

// #フォントサイズをremに変換
@mixin fz($size: 24, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) * 1rem;
}
// #clearfix
@mixin clearfix {
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: '.';
  }
}
// web fonts
@mixin sawarabimincho($weight) {
  font-family: "Sawarabi Mincho";
  font-weight: $weight;
}
// IE transform
@mixin rotate($val) {
  -ms-transform: rotate($val + deg);
}
// mixin
@mixin radius {
  border-radius: 12px;
  @media (max-width: 767px) {
    border-radius: 8px;
  }
}
@mixin fontSize($fz) {
  font-size: $fz+px;
  @media (max-width: 767px) {
    font-size: $fz - 2px;
  }
}
@mixin hoverTextColor($color) {
  color: $color;
  transition: 0.15s;
  &:hover {
    color: darken($color,15%);
  }
}
@mixin hoverColor($color) {
  background-color: $color;
  transition: 0.15s;
  &:hover {
    background-color: darken($color,15%);
  }
}
@mixin webFont {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon {
  font-family: "buy-iconfonts" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin sp {
  @media (max-width: 460px) {
    @content;
  }
}
@mixin maxw767 {
  @media (max-width: 767px) {
    @content;
  }
}

// z-index
$z-index_modal: 400; // モーダル表示用
$z-index_header: 300; // ヘッダー用
$z-index_fixed: 200; // 固定リンク用
$z-index_contents: 100; // 上記に該当しないコンテンツ部分用

// 第一引数に上記5つのうち該当する変数を指定する
// 第二引数で重なりの問題等で細かにz-indexを調整したい場合に数値を指定する。省略可(デフォルトが0)
@function zIndex ($parts, $adjustNum: 0) {
  @return  $parts + $adjustNum;
}

// アスタリスクをつける
@mixin AddAsterisk {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  &:before {
    display: block;
    content: '※';
  }
}
@mixin AddAsteriskWithNum {
  @include AddAsterisk;
  counter-increment: note;
  &:before {
    content: '※'counter(note);
    margin-right: 8px;
  }
}