@charset "UTF-8";
.m-sub_title {
  margin-bottom: 40px;
  color: #333333;
  font-size: 34px;
  font-weight: 900;
  line-height: 1.5;
}

.m-section_side_image-more .m-sub_title, .m-section_side_image-replace .m-sub_title {
  margin-bottom: 24px;
}

@media (max-width: 460px) {
  .m-sub_title {
    margin-bottom: 16px;
    font-size: 24px;
  }
}

.m-description {
  color: #333333;
  font-size: 17px;
  font-weight: 400;
  line-height: 2;
}

@media (max-width: 460px) {
  .m-description {
    font-size: 15px;
    line-height: 1.7;
  }
}

.m-sup {
  vertical-align: super;
  font-size: smaller;
}

.m-mv {
  margin-bottom: 160px;
  text-align: center;
}

@media (max-width: 460px) {
  .m-mv {
    margin-bottom: 94px;
  }
}

.m-mv--img {
  margin-bottom: 56px;
}

@media (max-width: 460px) {
  .m-mv--img {
    display: none;
  }
}

.m-mv--img-sp {
  display: none;
}

@media (max-width: 460px) {
  .m-mv--img-sp {
    display: block;
    width: 100%;
    height: 210px;
    margin-bottom: 24px;
    object-fit: cover;
  }
}

.m-mv--title {
  margin-bottom: 32px;
  color: #333333;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.5;
}

.m-mv--title:after {
  display: block;
  content: '';
  width: 78px;
  height: 8px;
  margin: 32px auto 0;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2278%22%20height%3D%228%22%3E%3Cg%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%205367%22%20transform%3D%22translate(-149%20-933)%22%3E%3Ccircle%20data-name%3D%22%E6%A5%95%E5%86%86%E5%BD%A2%204%22%20cx%3D%224%22%20cy%3D%224%22%20r%3D%224%22%20transform%3D%22translate(149%20933)%22%20fill%3D%22%23e87ba7%22%2F%3E%3Ccircle%20data-name%3D%22%E6%A5%95%E5%86%86%E5%BD%A2%205%22%20cx%3D%224%22%20cy%3D%224%22%20r%3D%224%22%20transform%3D%22translate(163%20933)%22%20fill%3D%22%23f6c01d%22%2F%3E%3Ccircle%20data-name%3D%22%E6%A5%95%E5%86%86%E5%BD%A2%206%22%20cx%3D%224%22%20cy%3D%224%22%20r%3D%224%22%20transform%3D%22translate(177%20933)%22%20fill%3D%22%23009cd7%22%2F%3E%3Ccircle%20data-name%3D%22%E6%A5%95%E5%86%86%E5%BD%A2%207%22%20cx%3D%224%22%20cy%3D%224%22%20r%3D%224%22%20transform%3D%22translate(191%20933)%22%20fill%3D%22%236bb846%22%2F%3E%3Ccircle%20data-name%3D%22%E6%A5%95%E5%86%86%E5%BD%A2%208%22%20cx%3D%224%22%20cy%3D%224%22%20r%3D%224%22%20transform%3D%22translate(205%20933)%22%20fill%3D%22%23e66f1c%22%2F%3E%3Ccircle%20data-name%3D%22%E6%A5%95%E5%86%86%E5%BD%A2%209%22%20cx%3D%224%22%20cy%3D%224%22%20r%3D%224%22%20transform%3D%22translate(219%20933)%22%20fill%3D%22%237d4294%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@media (max-width: 460px) {
  .m-mv--title {
    margin-bottom: 24px;
    font-size: 28px;
  }
  .m-mv--title:after {
    margin-top: 24px;
  }
}

.m-mv--description {
  color: #333333;
  font-size: 17px;
  font-weight: 400;
  line-height: 2;
}

@media (max-width: 460px) {
  .m-mv--description {
    font-size: 15px;
    line-height: 1.7;
  }
}

.m-section_side_image {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 1000px;
  margin: 0 auto 100px;
}

.m-section_side_image-left {
  flex-direction: row-reverse;
}

.m-section_side_image-end {
  margin-bottom: 160px;
}

.m-section_side_image-mb0 {
  margin-bottom: 0;
}

@media (max-width: 460px) {
  .m-section_side_image {
    flex-direction: column;
    width: 100%;
    padding: 0 32px;
    margin-bottom: 80px;
    box-sizing: border-box;
  }
  .m-section_side_image-mb0 {
    margin-bottom: 0;
  }
  .m-section_side_image-end {
    margin-bottom: 120px;
  }
  .m-section_side_image-more, .m-section_side_image-replace {
    flex-direction: column-reverse;
    margin-bottom: 100px;
  }
}

.m-section_side_image--wrapper {
  width: 336px;
}

.m-section_side_image--wrapper-feature {
  width: 360px;
}

@media (max-width: 460px) {
  .m-section_side_image--wrapper {
    width: 100%;
    margin-bottom: 24px;
  }
}

.m-section_side_image-more .m-section_side_image--wrapper, .m-section_side_image-replace .m-section_side_image--wrapper {
  width: 375px;
}

@media (max-width: 460px) {
  .m-section_side_image-more .m-section_side_image--wrapper, .m-section_side_image-replace .m-section_side_image--wrapper {
    width: auto;
  }
}

@media (max-width: 460px) {
  .m-section_side_image .m-sub_title {
    text-align: center;
  }
}

.m-section_side_image--description {
  color: #333333;
  font-size: 17px;
  font-weight: 400;
  line-height: 2;
}

@media (max-width: 460px) {
  .m-section_side_image--description {
    font-size: 15px;
    line-height: 1.7;
  }
}

.m-section_side_image--note {
  margin-top: 16px;
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.m-section_side_image--note:before {
  display: block;
  content: '※';
}

@media (max-width: 460px) {
  .m-section_side_image--note {
    margin-top: 8px;
    font-size: 12px;
  }
}

@media (max-width: 460px) {
  .m-section_side_image--img {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
}

@media (max-width: 460px) {
  .m-section_side_image-more .m-section_side_image--img, .m-section_side_image-replace .m-section_side_image--img {
    height: 150px;
    margin-bottom: 24px;
  }
}

.m-blog {
  width: 750px;
  padding: 24px 116px 32px;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 8px;
  background: url("/images/bg-texture.png") top center repeat;
}

@media (max-width: 460px) {
  .m-blog {
    width: calc(100% - 64px);
    padding: 24px;
    margin-top: 70px;
  }
}

.m-blog--title {
  margin-bottom: 16px;
  color: #333333;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
}

@media (max-width: 460px) {
  .m-blog--title {
    font-size: 16px;
  }
}

.m-blog--article {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

@media (max-width: 460px) {
  .m-blog--article {
    justify-content: flex-start;
  }
}

.m-blog--img {
  width: 142px;
  object-fit: cover;
}

@media (max-width: 460px) {
  .m-blog--img {
    width: 106px;
    margin-right: 16px;
  }
}

.m-blog--article_link {
  width: 352px;
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  text-decoration: underline;
  transition: 0.15s;
}

.m-blog--article_link:hover {
  opacity: 0.8;
}

@media (max-width: 460px) {
  .m-blog--article_link {
    width: auto;
    font-size: 10px;
  }
}

.m-blog--more_link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  transition: 0.15s;
}

.m-blog--more_link:hover {
  opacity: 0.8;
}

.m-blog--more_link:after {
  display: block;
  content: '';
  width: 6px;
  height: 10px;
  margin-left: 8px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226.175%22%20height%3D%2210%22%3E%3Cpath%20data-name%3D%22Icon%20material-keyboard-arrow-right%22%20d%3D%22M0%208.825%203.815%205%200%201.175%201.175%200l5%205-5%205Z%22%20fill%3D%22%236bb846%22%2F%3E%3C%2Fsvg%3E");
}

.m-insta_modal {
  display: none;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 36px;
  z-index: 400;
  background-color: rgba(0, 0, 0, 0.7);
  transition: 0.3s;
}

@media (max-width: 460px) {
  .m-insta_modal {
    padding-top: 56px;
  }
}

.m-insta_modal.js-show {
  opacity: 1;
}

.m-insta_modal--wrapper {
  position: relative;
  width: 485px;
  height: 100%;
  margin: 0 auto;
}

@media (max-width: 460px) {
  .m-insta_modal--wrapper {
    width: 300px;
  }
}

.m-insta_modal--close {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 39px;
  height: 39px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2239.358%22%20height%3D%2239.357%22%3E%3Cg%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%226%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%208149%22%3E%3Cpath%20d%3D%22m4.244%204.242%2030.871%2030.872%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017156%22%2F%3E%3Cpath%20d%3D%22M4.243%2035.114%2035.115%204.243%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017157%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  cursor: pointer;
}

@media (max-width: 460px) {
  .m-insta_modal--close {
    width: 16px;
    height: 16px;
    background-size: cover;
  }
}

.m-insta_modal--account_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 28px;
}

@media (max-width: 460px) {
  .m-insta_modal--account_wrapper {
    margin-bottom: 16px;
  }
}

.m-insta_modal--icon_img {
  margin-right: 16px;
}

@media (max-width: 460px) {
  .m-insta_modal--icon_img {
    width: 24px;
    margin-right: 8px;
  }
}

.m-insta_modal--account {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
}

@media (max-width: 460px) {
  .m-insta_modal--account {
    font-size: 12px;
  }
}

.m-insta_modal--img {
  display: block;
  width: 485px;
}

@media (max-width: 460px) {
  .m-insta_modal--img {
    width: 300px;
  }
}

.m-insta_modal .slick-prev, .m-insta_modal .slick-next {
  width: 24px;
  height: 40px;
}

.m-insta_modal .slick-prev:before, .m-insta_modal .slick-next:before {
  display: block;
  width: 24px;
  height: 40px;
  content: '';
  opacity: 1;
}

@media (max-width: 460px) {
  .m-insta_modal .slick-prev, .m-insta_modal .slick-next {
    width: 13px;
    height: 21px;
  }
  .m-insta_modal .slick-prev:before, .m-insta_modal .slick-next:before {
    width: 13px;
    height: 21px;
  }
}

.m-insta_modal .slick-prev {
  left: -64px;
}

.m-insta_modal .slick-prev:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224.191%22%20height%3D%2239.902%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%226%22%20d%3D%22M19.949%204.243%204.242%2019.951%2019.949%2035.66%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017155%22%2F%3E%3C%2Fsvg%3E");
}

@media (max-width: 460px) {
  .m-insta_modal .slick-prev {
    left: -20px;
  }
  .m-insta_modal .slick-prev:before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212.742%22%20height%3D%2221.244%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%223%22%20d%3D%22m10.618%202.121-8.5%208.5%208.5%208.5%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017153%22%2F%3E%3C%2Fsvg%3E");
  }
}

.m-insta_modal .slick-next {
  right: -64px;
}

.m-insta_modal .slick-next:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224.191%22%20height%3D%2239.902%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%226%22%20d%3D%22m4.242%2035.66%2015.707-15.708L4.242%204.243%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017154%22%2F%3E%3C%2Fsvg%3E");
}

@media (max-width: 460px) {
  .m-insta_modal .slick-next {
    right: -20px;
  }
  .m-insta_modal .slick-next:before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212.742%22%20height%3D%2221.244%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%223%22%20d%3D%22m2.124%2019.123%208.5-8.5-8.5-8.5%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017153%22%2F%3E%3C%2Fsvg%3E");
  }
}

.m-insta_modal .slick-dots {
  bottom: -60px;
}

@media (max-width: 460px) {
  .m-insta_modal .slick-dots {
    bottom: -50px;
  }
}

.m-insta_modal .slick-dots li button:before {
  opacity: 1;
  color: #ffffff;
}

.m-insta_modal .slick-dots li.slick-active button:before {
  color: #e87ba7;
}
